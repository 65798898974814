import React, { useState } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../components/common/customMedia'
import { useFormik } from 'formik'
import axios from 'axios'

import { Layout } from '../../components/Layout'
import { SEO } from '../../components/common/SEO'
import { PcBr } from '../../components/common/PcBr'
import { Breadcrumb } from '../../components/common/Breadcrumb'
import { ContactUs } from '../../components/common/ContactUs'
import { MyButton } from '../../components/common/MyButton'

const IndexPage = () => {
  const [complete, setComplete] = useState(false)

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      name: '',
      email: '',
      employment: '',
      question: ''
    },
    validate: values => {
      const errors: {[key: string]: string} = {}
      
      if (!values.name) {
        errors.name = 'お名前は必須です'
      }
      if (!values.email) {
        errors.email = 'メールアドレスは必須です'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'メールアドレス が無効な形式です'
      }
      if (!values.employment) {
        errors.employment = 'ご希望の勤務形態は必須です'
      }
    
      return errors
    },
    onSubmit: values => {
      axios.post('https://us-central1-shinbi-do.cloudfunctions.net/sendMail', {
        data: values
      }).then(res => {
        console.log(res)
        setComplete(true)
      }).catch(e => {
        console.log(e)
      })
    },
  })

  return (
    <Layout>
      <SEO 
        title='募集要項・応募フォーム | シンビ堂'
        description='シンビ堂の求人・転職応募フォームです。福利厚生も充実しており、共に働く職場環境にも気を配る、将来が想像できる会社です。是非、一緒に働いてみませんか？求人・転職・お問合せなど、お気軽にご連絡ください。'
        keywords={['ジェネリック医薬品','軽井沢','シンビ堂','調剤薬局','薬局']}
      />
      <h1>募集要項 応募フォーム 薬剤師 採用 求人 転職 | シンビ堂</h1>

      <EntryDetailContainer>
        <BreadcrumbWrapper>
          <Breadcrumb color='white' current='エントリー' previous={{ to: 'recruit', text: '採用情報' }} />
        </BreadcrumbWrapper>
        <Title>ENTRY</Title>
        <SubTitle>募集要項・ご応募</SubTitle>
        <Content>  
          <DetailWrapper>
            <DetailTitle>勤務地</DetailTitle>
            <DetailContent>軽井沢・御代田町</DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>仕事内容</DetailTitle>
            <DetailContent>調剤薬局業務</DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>福利厚生</DetailTitle>
            <DetailContent>
              ・各種社会保険（健康保険・厚生年金・雇用保険）完備<br />
              ・交通費支給<br />
              ・薬剤師賠償責任保険加入<br />
              ・薬剤師会費負担<br />
              ・登録販売者資格取得支援 ＊薬剤師以外を対象
            </DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>勤務体系</DetailTitle>
            <DetailContent>
              ・正社員<br />
              ・パートタイム(勤務日数・時間応相談)
            </DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>給与</DetailTitle>
            <DetailContent>経験、年齢により考慮</DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>休暇</DetailTitle>
            <DetailContent>
              完全週休２日制<br />
              有給休暇：初年度１０日（入社６ヵ月以降発生）/次年度以降勤務年数に応<PcBr />じて付与<br />
              年末年始/慶弔休暇/産前産後休暇/育児休暇
            </DetailContent>
          </DetailWrapper>
          <DetailWrapper>
            <DetailTitle>応募方法</DetailTitle>
            <DetailContent>下記メールフォーム・お電話にてご応募ください。ハローワーク小諸、ハ<PcBr />ローワーク佐久からもご応募頂けます。</DetailContent>
          </DetailWrapper>
        </Content>
      </EntryDetailContainer>

      <FormContainer>
        <FormTitle>ご応募はこちらから</FormTitle>
        <Form onSubmit={formik.handleSubmit}>
          <Label>お名前</Label>
          <Input name='name' onChange={formik.handleChange} value={formik.values.name} disabled={complete} />

          <Label>メールアドレス</Label>
          <Input name='email' type='email' onChange={formik.handleChange} value={formik.values.email} disabled={complete} />

          <Label>ご希望の勤務形態</Label>
          <SelectWrapper>
            <Select name='employment' onChange={formik.handleChange} value={formik.values.employment} disabled={complete}>
              <option value=''>選択してください</option>
              <option value='経験を生かした薬剤師'>経験を生かした薬剤師</option>
              <option value='研修充実！ 調剤薬局勤務未経験者'>研修充実！ 調剤薬局勤務未経験者</option>
              <option value='シフト制メリハリある勤務形態'>シフト制メリハリある勤務形態</option>
              <option value='子育てと両立出来る薬剤師'>子育てと両立出来る薬剤師</option>
              <option value='医療事務'>医療事務</option>
            </Select>
          </SelectWrapper>

          <Label>その他ご質問等</Label>
          <Textarea name='question' onChange={formik.handleChange} value={formik.values.question} disabled={complete}/>

          <Error>
            {formik.errors.name ? <div>{formik.errors.name}</div> : null}
            {formik.errors.email ? <div>{formik.errors.email}</div> : null}
            {formik.errors.employment ? <div>{formik.errors.employment}</div> : null}
          </Error>

          <ButtonWrapper>
            <MyButton color={complete ? 'blue' : 'pink'} text={complete ? '受け付けました' : '応募する'} type='submit' disabled={complete} />
          </ButtonWrapper>
        </Form>
      </FormContainer>
      <ContactUs />
    </Layout>
  )
}

const EntryDetailContainer = styled.div`
  ${customMedia.lessThan("medium")`
    padding-bottom: 30px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #00559F;
  color: #fff;
  overflow: hidden;
`

const Title = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 2.6rem;
    margin-top: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 3.6rem;
    font-weight: bold;
    margin-top: 25px;
  `}

  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
`

const SubTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    margin-top: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 10px;
  `}

  font-family: 'Kosugi Maru', sans-serif;
  letter-spacing: 0.36em;
  text-align: center;
`

const Content = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 56.5px;
    width: 650px;
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px 0 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 20px 0 0 100px;
  `}
`

const DetailWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin-bottom: 28px;
  `}
`

const DetailTitle = styled.div`
  ${customMedia.lessThan("medium")`
    border-radius: 12px;
    letter-spacing: 0.24em;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 14px;
    padding: 3px 0;
    letter-spacing: 0.2em;
    height: 23px;
    width: 115px;
  `}

  background-color: #fff;
  color: #00559F;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
`

const DetailContent = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 10px;
    line-height: 25px;
  `}

  font-size: 1.4rem;
  letter-spacing: 0.1em;
`

const FormContainer = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
    margin-bottom: -60px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 500px;
  `}

  background-color: #fff;
  overflow: hidden;
`

const FormTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 28px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 54px;
  `}
  
  color: #0055A5;
  font-weight: bold;
  text-align: center;
`


const Form = styled.form`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Label = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin: 15px 0 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin: 20px 0 5px;
  `}

  color: #0055A5;
  font-weight: bold;
  letter-spacing: 0.04em;
`

const Input = styled.input`
  border: 2px #0055A5 solid;
  border-radius: 5px;
  font-size: 1.6rem;
  padding: 8px;
  height: 30px;
  width: 100%;
`

const SelectWrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 2px #0055A5 solid;
  height: 30px;
  overflow: hidden;
  position: relative;

  :before {
    background: #0055A5;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
  }

  :after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #fff;
    position: absolute;
    right: 8px;
    top: 10px;
  }
`

const Select = styled.select`
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  color: #0055A5;
  font-size: 1.4rem;
  padding: 2.5px 8px 0;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  width: 100%;
`

const Textarea = styled.textarea`
  border: 2px #0055A5 solid;
  border-radius: 5px;
  font-size: 1.6rem;
  height: 270px;
  padding: 8px;
  resize: none;
  width: 100% !important;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 25px;
  `}
`

const Error = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
  
  font-size: 1.6rem;
  color: #ff0003;
`

export default IndexPage
