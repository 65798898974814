import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../customMedia'
import { SpBr } from '../../../components/common/SpBr'

export const ContactUs = () => {
  return (
    <>
      <FlowAfterApplication>応募後、スタッフよりメールで見学・面接の日程を<SpBr />ご相談させていただきます。</FlowAfterApplication>
      <Container>
        <Text>求人・採用のお問い合わせはこちら</Text>
        <Tel>0267-32-8622</Tel>
        <BusinessHours>
          <span>受付時間</span>
          <span>月～土 9:00～19:00</span>
        </BusinessHours>
      </Container>
    </>
  )
}

const FlowAfterApplication = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: -0.02em;
    margin: 65px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    background-color: #EBEFF3;
    line-height: 48px;
    letter-spacing: 0.14em;
    height: 48px;
    margin-top: 25px;
  `}

  color: #00559F;
  text-align: center;
`

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`

  `}

  background-color: #00559F;
  overflow: hidden;
`

const Text = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.6rem;
    letter-spacing: 0.08em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 35px;
  `}

  color: #fff;
  font-weight: bold;
  text-align: center;
`

const Tel = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 3.2rem;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 4.8rem;
    margin-top: 15px;
  `}

  color: #fff;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-align: center;
`

const BusinessHours = styled.div`
  ${customMedia.lessThan("medium")`
    text-align: center;
    margin: 15px 0;

    span:first-child {
      background-color: #fff;
      color: #00559F;
      border-radius: 12px;
      font-size: 1.2rem;
      letter-spacing: 0.12em;
      padding: 5px 10px;
    }

    span:nth-child(2) {
      color: #fff;
      font-size: 1.4rem;
      letter-spacing: -0.02em;
      margin-left: 10px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    text-align: center;
    margin: 15px 0 30px;

    span:first-child {
      background-color: #fff;
      color: #00559F;
      border-radius: 14px;
      font-size: 1.4rem;
      letter-spacing: 0.12em;
      padding: 5px 10px;
    }

    span:nth-child(2) {
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: -0.02em;
      margin-left: 10px;
    }
  `}

`

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`
