import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../customMedia'


export interface Props {
  color: string
  current: string
  previous?: {[key: string]: string}
}

export const Breadcrumb = (props: Props) => {
  const { color, current, previous } = props;
  
  return (
    <Container>
      <HomeLink>
        <Link to='/'>HOME</Link>
      </HomeLink>
      <Arrow color={color}>></Arrow>
      {previous && 
        <>
          <PreviousLink>
            <Link to={previous.to}>{previous.text}</Link>
          </PreviousLink>
          <Arrow color={color}>></Arrow>
        </>
      }
      <CurrentLink color={color}>
        {current}
      </CurrentLink>
    </Container>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    letter-spacing: 0.08em;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.2rem;
    letter-spacing: 0.18em;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
`

const HomeLink = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.2rem;
  `}
  
  color: #CA2F68;

  a:visited {
    color: #CA2F68;
  }
`

const Arrow = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    margin: 0 7px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 10px;
  `}

  color: ${props => props.color === 'blue' ? '#0055A5'
    : props.color === 'white' ? '#fff'
    : '#000'
  };
`

const PreviousLink = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  color: #CA2F68;

  a:visited {
    color: #CA2F68;
  }
`

const CurrentLink = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  color: ${props => props.color === 'blue' ? '#0055A5'
    : props.color === 'white' ? '#fff'
    : '#000'
  };
`